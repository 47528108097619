<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Tambah AP Saldo Awal" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Nomor *</label>
            <vs-input class="w-full" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Person Type *</label>
            <v-select :options="['REKANAN', 'STAF']" :clearable="false" v-model="data.tipe_kreditur" @change="onChangeTipeKreditur($event)"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full" v-if="data.tipe_kreditur === 'REKANAN'">
            <label class="ml-1 text-xs">Rekanan *</label>
            <div @click="modalRekanan.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_kreditur" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-6/12 w-full" v-if="data.tipe_kreditur === 'STAF'">
            <label class="ml-1 text-xs">Staf *</label>
            <div @click="modalStaf.active = true">
              <vx-input-group>
                <vs-input :value="data.nama_kreditur" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tgl Due *</label>
            <flat-pickr class="w-full" v-model="data.tgl_due"></flat-pickr>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Saldo Awal *</label>
            <v-money class="w-full" v-model="data.total_nilai"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal rekanan-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Rekanan"
                :active="modalRekanan.active"
                v-on:update:active="modalRekanan.active = $event">
        <Rekanan v-if="data.tipe_kreditur === 'REKANAN'" :selectable="true" :externalFilter="filterRekanan" @selected="onSelectedModalRekanan"/>
      </vs-popup>

      <!--modal staf-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active="modalStaf.active"
                v-on:update:active="modalStaf.active = $event">
        <Staf v-if="data.tipe_kreditur === 'STAF'" :selectable="true" :externalFilter="filterStaf" @selected="onSelectedModalStaf"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ApSaldoAwalRepository from '@/repositories/accounting/ap-saldo-awal-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Rekanan from '@/views/pages/master/rekanan/Rekanan'
import Staf from '@/views/pages/master/staf/Staf'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'ApSaldoAwalAdd',
  props: ['isActive'],
  components: {
    VMoney,
    ValidationErrors,
    flatPickr,
    Rekanan,
    Staf,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      proyeks: [],
      modalRekanan: {
        active: false
      },
      modalStaf: {
        active: false
      },
      data: {
        id_proyek: null,
        id_kreditur: null,
        tipe_kreditur: 'REKANAN',
        tgl_due: moment().format('YYYY-MM-DD'),
        total_nilai: null,
        keterangan: null
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => item.jml_total)
    },
    filterRekanan () {
      return { id_proyek: this.data.id_proyek }
    },
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      ApSaldoAwalRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_proyek: this.data.id_proyek,
        id_kreditur: this.data.id_kreditur,
        tipe_kreditur: this.data.tipe_kreditur,
        tgl_due: this.data.tgl_due,
        total_nilai: this.data.total_nilai,
        keterangan: this.data.keterangan
      }
    },

    onSelectedModalRekanan (item) {
      this.data.id_kreditur = item.id
      this.data.nama_kreditur = item.nama
      this.data.keterangan = `Saldo Awal Hutang Rekanan ${item.nama}`
      this.modalRekanan.active = false
    },

    onSelectedModalStaf (item) {
      this.data.id_kreditur = item.id
      this.data.nama_kreditur = item.nama
      this.data.keterangan = `Saldo Awal Hutang Staf ${item.nama}`
      this.modalStaf.active = false
    },

    onChangeTipeKreditur (tipeKreditur) {
      this.data.id_kreditur = null
      this.data.nama_kreditur = null
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
